<template>
  <div style="padding:10px;background:#fff">
    <div style="margin:10px 0">
      <a-button type="primary" @click="addShow = true">新建关联客户</a-button>
<!--      <a-button type="primary" @click="loadData">刷新数据(测试用)</a-button>-->
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.groupId" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
<!--      <template #customerContent="{ record }">-->
<!--        &lt;!&ndash; <span v-for="(item, index) in record.relevance" :key="index">{{ item.name }}({{ item.mobile }})<span-->
<!--            v-show="index + 1 < record.relevance.length">、</span></span> &ndash;&gt;-->
<!--      </template>-->

      <template #operate="{ record }">
        <div style="display:flex;justify-content: space-around;">
          <a @click="add(record)">添加客户</a>
          <a @click="edit(record)">编辑</a>
          <a @click="remove(record)">解除关联</a>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="addShow" title="新建关联客户" :maskClosable="false" @ok="confirmAdd" @cancel="closeAdd">
    <div>
      <div>选择关联客户<span style="color:#f00">*</span></div>
      <a-select v-if="addShow" style="width:100%" placeholder="搜索并选择客户" @search="addEntpSearch" labelInValue
        :filterOption="false" show-search>

        <template v-if="fetching" #notFoundContent>
          <a-spin size="small" />
        </template>
        <a-select-option v-for="item in enterprises" :key="item.name" :value="item.id" @click="enterpriseChoose(item)">
          {{ item.name }}—{{ item.mobile }}
        </a-select-option>
      </a-select>
      <a-tag closable @close="deleteItem(item)" v-for="item in customList" :key="item.mobile">{{ item.name }}—{{
        item.mobile
      }}</a-tag>
    </div>
  </a-modal>
  <a-modal v-model:visible="editShow" title="编辑" @ok="confirmEdit" @cancel="closeEditOpen">
    <a-table size="small" :columns="editColumns" :row-key="record => record.id" :data-source="editListData" bordered
      :loading="editLoading">

      <template #operate="{ record }">
        <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="confirmDelete(record)" @cancel="cancel">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { pageAll } from '@/api/crm/customer'
import { getPage, addData, customerAdd, getCustomerData, deleteCustomer, removeCustomer } from '@/api/crmManagement/returnCustomer'
import { confirmWindow } from '@/utils/util'
import { message } from 'ant-design-vue'

export default {
  setup () {
    const state = reactive({
      loading: false,
      editLoading: false,
      fetching: false,
      addShow: false,
      editShow: false,
      timeOut: null,
      addForm: {},
      customList: [],
      listData: [],
      detailData: {},
      editListData: [],
      enterprises: [],
      columns: [
        {
          title: '关联客户',
          dataIndex: 'customerContent',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          width: '10%',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '10%',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          width: '10%',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      editColumns: [
        {
          title: '客户名称',
          dataIndex: 'customerName',
          align: 'center'
        },
        {
          title: '电话',
          dataIndex: 'customerMobile',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          align: 'center',
          slots: {
            customRender: 'operate'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      state.loading = true
      getPage({
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        state.listData = res.records
        state.pagination.total = res.total
      }).finally(() => { state.loading = false })
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const addEntpSearch = val => {
      if (val.length > 0) {
        state.fetching = true
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          pageAll({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data.records
              state.timeOut = null
            } else {
              state.enterprises = []
            }
          }).finally(() => { state.fetching = false })
        }, 1000)
      } else {
        state.enterprises = []
      }
    }
    const enterpriseChoose = item => {
      state.customList.push(item)
    }
    const deleteItem = item => {
      state.customList.splice(state.customList.indexOf(item), 1)
    }
    const closeAdd = () => {
      state.editShow = []
      state.customList = []
      state.detailData = {}
      state.addShow = false
    }
    const closeEditOpen = () => {
      state.editListData = []
      state.editShow = false
      state.detailData = {}
    }
    const confirmAdd = () => {
      const requireArr = []
      if (!state.detailData.groupId) {
        state.customList.forEach(item => {
          requireArr.push({
            customerId: item.id,
            customerMobile: item.mobile,
            customerName: item.name
          })
        })
        setTimeout(() => {
          addData({
            customerList: requireArr
          }).then(res => {
            if (res.code === 10000) {
              loadData()
              message.success('新建成功')
              state.addShow = false
            }
          })
        }, 10)
      } else {
        state.customList.forEach(item => {
          requireArr.push({
            customerId: item.id,
            customerMobile: item.mobile,
            customerName: item.name
          })
        })
        setTimeout(() => {
          customerAdd({
            customerList: requireArr,
            groupId: state.detailData.groupId
          }).then(res => {
            if (res.code === 10000) {
              loadData()
              message.success('额外新建客户成功')
              state.addShow = false
            }
          })
        }, 10)
      }
    }
    const confirmEdit = () => {

    }
    const add = record => {
      state.addShow = true
      state.detailData = record
    }
    const edit = record => {
      state.editShow = true
      if (record) state.detailData = record
      state.editLoading = true
      getCustomerData(state.detailData.groupId).then(res => {
        if (res.code === 10000) {
          state.editListData = res.data
        }
      }).finally(() => { state.editLoading = false })
    }
    const confirmDelete = (record) => {
      deleteCustomer({
        customerId: record.customerId,
        groupId: state.detailData.groupId
      }).then(res => {
        if (res.code === 10000) {
          message.success('删除成功')
          edit()
          loadData()
        }
      })
    }
    const remove = record => {
      confirmWindow('解散该组', requireAPI)
      state.detailData = record
      function requireAPI (resolve) {
        removeCustomer(state.detailData.groupId).then(res => {
          if (res.code === 10000) {
            message.success('解除关联成功')
          }
        }).finally(() => { resolve() })
      }
    }
    return {
      ...toRefs(state),
      handleTableChange,
      addEntpSearch,
      deleteItem,
      confirmAdd,
      confirmEdit,
      remove,
      add,
      edit,
      confirmDelete,
      enterpriseChoose,
      loadData
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
